<template>
    <el-form :model="form.data" ref="videoForm">

        <div class="row">
            <div class="col-lg-5 col-xl-3 mt-7 mt-md-0">
                <div class="card">
                    <div class="card-body d-flex flex-column p-9">
                        <div class="fv-row mb-6">
                            <label class="fs-6 required fw-bold mb-4">{{ $t('pages.video.upload.cols.group') }}:</label>
                            <el-form-item prop="group_id" :rules="$validation.getMessage(['required'])">
                                <el-select v-model="form.data.group_id" :placeholder="$t('common.chooseSelect')" filterable class="w-100">
                                    <el-option v-for="(group, groupIndex) in videoGroups" :key="groupIndex" :value="group.id" :label="group.name"></el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                        <div class="fv-row">
                            <label class="fs-6 fw-bold mb-2">{{ $t('pages.video.upload.cols.option') }}:</label>
                            <div>
                                <div class="form-check form-check-custom form-check-solid my-2">
                                    <input class="form-check-input" type="checkbox" v-model="form.data.convert_hls" id="exportHLS">
                                    <label class="form-check-label" for="exportHLS">
                                        {{$t('pages.video.upload.option.convertHls') }}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-lg-7 col-xl-9">
                <div class="card">
                    <div class="card-body d-flex flex-column p-9">
                        <div class="fv-row">
                            <label class="required fs-6 fw-bold mb-2">{{ $t('pages.video.upload.cols.video.title') }}:</label>
                            <el-form-item prop="file">
                                <el-upload :file-list="form.fileList" accept="video/*" class="upload-file mt-2" :on-change="handleChangeFile" :on-remove="handleChangeFile" :auto-upload="false" drag multiple :limit="5">
                                    <el-icon class="el-icon--upload">
                                         <span class="svg-icon">
                                             <inline-svg src="/media/icons/duotune/files/fil022.svg" />
                                         </span>
                                    </el-icon>
                                    <div class="el-upload__text">{{ $t('pages.video.upload.cols.video.dragFile')}}</div>
                                </el-upload>
                            </el-form-item>
                        </div>
                        <div class="row mb-6" v-for="(file, fileIndex) in form.fileList" :key="fileIndex">
                            <label class="col-lg-4 col-form-label fs-6 fw-bold mb-2">{{ sprintf($t('pages.video.upload.videoTitlePattern'), [(fileIndex + 1)]) }}:</label>
                            <div class="col-lg-8 fv-row">
                                <el-input v-model="form.fileList[fileIndex].name" type="text"/>
                            </div>
                        </div>
                        <div class="text-center mt-7" v-if="Object.keys(form.uploadProgresses).length">
                            <h1 class="fw-bold">{{ $t('pages.video.upload.cols.uploadState') }}</h1>
                            <div class="d-flex justify-content-center" v-for="(progress, progressKey, progressIndex) in form.uploadProgresses" :key="progressIndex">
                                <div class="d-flex align-items-center flex-column justify-content-center mt-3 w-50">
                                    <div class="d-flex justify-content-between w-100 mt-auto mb-2">
                                        <span class="fw-bold fs-6 text-gray-600">{{ progress.name }}</span>
                                        <span class="fw-bolder fs-6">{{ progress.percentage }}%</span>
                                    </div>

                                    <div class="h-8px mx-3 w-100 bg-light mb-3 rounded">
                                        <div class="bg-success rounded h-8px" role="progressbar" v-bind:style="{'width': progress.percentage +'%'}" :aria-valuenow="progress.percentage" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer d-flex justify-content-end py-6 px-9">
                        <button @click.prevent="onSubmit" :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="button">
                            <span v-if="!form.loading" class="indicator-label">{{ $t("pages.video.upload.submit") }}</span>
                            <span v-if="form.loading" class="indicator-progress">
                                {{ $t("messages.wait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </el-form>
</template>

<script>
export default {
    name: "index",
    data(){
        return {
            form: {
                loading: false,
                fileList: [],
                uploadList: [],
                data: {
                    convert_unsupported: true,
                    convert_hls: true
                },
                uploadProgresses: {},
            },
        }
    },
    computed: {
        videoGroups() {
            return this.$store.state.video.group.table.data;
        },
    },
    mounted(){
        this.setCurrentPageBreadcrumbs(this.$t("pages.video.upload.title"), [this.$t("menu.contentManagement.title"), this.$t("menu.contentManagement.video.title")]);
        this.$store.dispatch('video/group/get', {
            page: { pageSize: 9999 }
        });

        this.bar = 10;
        setInterval(() => {
            this.bar += 5;
        }, 1000);
    },
    methods: {
        onSubmit(){
            this.$refs.videoForm.validate((valid) => {
                if(valid) {
                    if(!(this.form.fileList.length > 0)){
                        this.$notify({
                            type: 'warning',
                            title: this.$t("messages.warning"),
                            message: this.$t("pages.video.upload.warnings.requiredFile"),
                        })
                        return false;
                    }

                    this.form.loading = true;

                    let multipleRequest = this.form.fileList.map((file) => {
                        const formData = new FormData();
                        formData.append("file", file.raw);
                        formData.append("title", file.name);

                        for (let key in this.form.data){
                            formData.append(key, (typeof(this.form.data[key]) == 'boolean' ? (Number(this.form.data[key])) : this.form.data[key]));
                        }

                        return this.axios.post(this.endpoint.get('video'), formData, {
                            onUploadProgress: (progressEvent) => {
                                const {loaded, total} = progressEvent;
                                let fileUploadProgress = Math.floor((loaded * 100) / total);

                                this.form.uploadProgresses[file.uid] = {
                                    name: file.name,
                                    percentage: fileUploadProgress
                                };
                            }
                        }).catch((err) => {
                            this.axios.post(this.endpoint.get('log_register', 'system'), {
                                error: err.response,
                                upload_content: file
                            });
                        });
                    });

                    this.requestUpload(multipleRequest).then((response) => {
                        this.onResponse(response, () => {
                            setTimeout(() => {
                                this.$router.push({path: "/video"});
                            }, 500);
                        });
                    }).catch((error) => {
                        this.$notify({
                            type: 'error',
                            title: this.$t("messages.error"),
                            message: error,
                        });
                    }).finally(() => {
                        this.form.loading = false;
                        this.form.uploadProgresses = {};
                    });
                } else {
                    return false;
                }
            });
        },
        async requestUpload(multipleRequest) {
            return new Promise((resolve, reject) => {
                this.axios.all(multipleRequest).then(this.axios.spread((...responses) => {
                    let result = [];

                    for (let i = 0; i < responses.length; i++) {
                        let handleResponse = responses[i].data;

                        if (!handleResponse.status) {
                            break;
                        }

                        result.push(handleResponse.data);
                    }

                    if (result.length == responses.length) {
                        resolve({status: true, data: result});
                    } else {
                        reject('request_count_dont_match_with_response_count');
                    }
                })).catch((errors) => {
                    reject('occurred_any_error');
                })
            });
        },
        handleChangeFile(file, fileList){
            this.form.fileList = fileList;
            this.form.uploadList = fileList;
        },
    }
}
</script>

<style>
.upload-file .el-upload {
    width: 100%;
}

.upload-file .el-upload .el-upload-dragger{
    width: 100%;
}

.upload-file .svg-icon svg {
    height: 1em;
    width: 1em;
}
</style>